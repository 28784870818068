import React, { useEffect, useState } from 'react';
import { Typography, AppBar, Toolbar } from '@mui/material';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import { useRollbar } from '@rollbar/react';
import { createTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import FhxContent from '../content/FhxContent';
import FhxLoading from '../content/FhxLoading';
import FhxLocalization from '../content/FhxLocalization';
import FhxFaqDialog from '../content/FhxFaqDialog';
import IntakeServices from '../services/IntakeServices';
import customizeTheme from '../common/Themes';
import mapleTheme from '../common/MapleTheme';

const services = new IntakeServices();

let DISABLE_BANNER;

function IntakeApp() {
  const rollbar = useRollbar();
  const { i18n } = useTranslation();
  const [data, setData] = useState(null);
  const [language, setLanguage] = useState('en');
  const [theme, setTheme] = useState(createTheme({}));
  const [customLocalization, setCustomLocalization] = useState(null);

  useEffect(() => {
    const handleErrorResponse = (status, error) => {
      if (status === 410) {
        setData('deactivated');
        return;
      }
      const errorType = status === 404 ? 'Invalid mkey' : 'Unexpected error';
      rollbar[status === 404 ? 'info' : 'error'](`${errorType} in getIntake`, error);
      setData('error');
    };

    services
      .getIntake(global.mkey)
      .then((response) => {
        if (!response?.result?.data) {
          rollbar.error('Missing or malformed response data in getIntake', { mkey: global.mkey });
          setData('error');
          return;
        }

        if (response.result.status === 200) {
          // We want to wait until language is loaded before setting data
          if (response.result.data.theme) {
            const themeSetting = JSON.parse(response.result.data.theme);
            if (themeSetting.customTheme === 'maple') {
              setTheme(createTheme(mapleTheme()));
            } else {
              setTheme(createTheme(customizeTheme(themeSetting)));
            }
            DISABLE_BANNER = !themeSetting.bannerOn;
          }
          let localization = response.result.data.localization || 'en';
          if (
            response.result.data.localization === 'ar_male'
            || response.result.data.localization === 'ar_female'
          ) {
            localization = 'ar';
          }

          if (customLocalization && customLocalization === 'arabic') {
            localization = 'ar';
          }
          setLanguage(localization);
          i18n
            .changeLanguage(localization)
            .then(() => {
              setData(response.result.data);
            })
            .catch((error) => {
              rollbar.error('Failed to change language', error);
            });
        } else {
          setData('error');
        }
      })
      .catch((error) => {
        if (error?.response) {
          handleErrorResponse(error.response.status, error);
        } else {
          rollbar.error(error.code === 'ERR_NETWORK' ? 'Network Error in getIntake' : 'Unknown Error in getIntake', error);
          setData('error');
        }
      });
  }, [i18n, customLocalization]);

  if (!data) {
    return <FhxLoading />;
  }
  if (data === 'deactivated') {
    return <FhxContent initialContent="deactivated" />;
  }
  if (data === 'error') {
    return <FhxContent initialContent={null} />;
  }

  return (
    <FhxLocalization theme={theme} localization={i18n}>
      {data.siteName && !DISABLE_BANNER ? (
        <AppBar position="relative">
          <Toolbar>
            <WbSunnyIcon sx={{ margin: '20px' }} />
            <Typography variant="h6" sx={{ width: '100%' }}>
              {data.siteName}
            </Typography>
            <FhxFaqDialog faq={data.faq} />
          </Toolbar>
        </AppBar>
      ) : null}
      <FhxContent
        initialContent={data.content}
        mkey={global.mkey}
        medicationFormType={data.medicationFormType}
        isTestDrive={data.isTestDrive === true}
        setCustomLocalization={setCustomLocalization}
        language={language}
      />
    </FhxLocalization>
  );
}

export default IntakeApp;
